import React from 'react';
import Botones from '../Botones/Botones';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


const Portada  = (props) => {
    return (
      <div className="App fondo size">
        <div className="justify-content-center">
            <div className="col-12 seccion d-flex espacio justify-content-center">
              <div className="fondo portada justify-content-center text-center">
                {/* <div className="bg-light rounded-circle profile " style={{width: 310, height: 310}} /> */}
                <h1 className="letra titulo" style={{fontWeight: 500, fontSize:50, marginTop: 50}}>TibarMet</h1>
                <div className="col-12">
                </div>
                <button disable type="button" class="btn boton3 btn-xl m-2" style={{fontWeight: 600, fontSize:25}}>Próximamente</button>
                <h4 className="text-light">tibarmet@gmail.com</h4>
              </div>
            </div>
        </div>
      </div>
      // <div className="justify-content-center text-center">
      //   <h1 className="letra titulo " style={{fontWeight: 500, fontSize:50, marginTop: 50}}>TibarMet</h1>
      //   <div className="col-12">
      //     <iframe className="col-12 col-md-8 live" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ficamilita%2Fvideos%2F575059499767023%2F&show_text=0&width=560"  scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
      //     <iframe className="col-12" src="https://docs.google.com/forms/d/e/1FAIpQLSfkJTuhextgIDmDQBrAqdbd_6Xc1JqKJgbsnIQwK0az6F0PbQ/viewform?embedded=true" height="630" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
      //   </div>
      //  {/* <button disable type="button" class="btn boton3 btn-xl m-2" style={{fontWeight: 600, fontSize:25}}>Próximamente</button>
      //  <h4 className="text-light">tibarmet@gmail.com</h4> */}
      //</div>
    );
  };
export default Portada;

