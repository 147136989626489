// Dependencias 
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Componentes
import Navegador from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Contenido from './components/Contenido/Contenido'


class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  render () {
    const { children } = this.props;
    return (
      <div>
        <Navegador/>
        <Contenido body={children}/>
        <Footer />
      </div>
    );
  }
}

export default App;
