import React from 'react';
import { Button } from 'reactstrap';

function Botones() {
  return (
    <div className="container pt-5 d-flex justify-content-center">
        <a href="https://soundcloud.com/mora-violante/sets/experiencia-radial" target="blank"><button type="button" class="btn boton1 btn-circle btn-xl m-2"><i class="fa fa-play"></i></button></a>
        <a href="https://drive.google.com/drive/folders/1zit-Kqa8RePAAEkqCe88OZZKIEZIdGSH" target="blank"><button type="button" class="btn boton2 btn-circle btn-xl m-2"><i class="fa fa-video-camera"></i></button></a>
        <a href="https://linktr.ee/moviolante" target="blank"><button type="button" class="btn boton3 btn-circle btn-xl m-2"><i class="fa  fa-heart"></i></button></a>
    </div>
  );
}

export default Botones; 

