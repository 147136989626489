import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import Portada from '../Portada/Portada';
import Navegador from '../Navbar/Navbar';
import Video from '../Video/Video';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';

function CursoUno() {
  return (
    <div className="App fondo size">
        <h1 className="text-light robotoTypo p-4">Curso básico</h1>
        <p>Descripción....</p>
        <a mp-mode="dftl" href="https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=9321696-ba8572d5-93df-475a-91ee-1ae8f344e89c" class='btn btn-light'><b>Pagar curso basico</b></a>
    </div>
  );
}

export default CursoUno; 