// Dependencias
import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Rutas
import AppRoutes from '../src/components/routes/routes';

// Assets
import './index.css';
import './App.css';
//import * as registerServiceWorker from './serviceWorker';


render(
    <Router>
        <AppRoutes />
    </Router>,
    document.getElementById('root')
);
