import React, { Component } from 'react';
import '../../App.css';
import Portada from '../Portada/Portada';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Cursos from '../Cursos/Cursos';
import CursoUno from '../CursoUno/CursoUno';


class Home extends Component{
  render () {
    return (

      <div className="App fondo size">
        `<div className="justify-content-center">
          <Portada />
        </div>
    </div>
    )
  }
}

export default Home; 

