import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import Portada from '../Portada/Portada';
import Navegador from '../Navbar/Navbar';
import CursoUno from '../CursoUno/CursoUno';
import Home from '../Home/Home';
import Video from '../Video/Video';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Cursos() {
  return (
    <div className="App fondo size">
        <h1 className="text-light robotoTypo p-4">Nuestros Cursos</h1>
        <div className="col-xl-8 col-lg-10 col-md-12 col-12 d-md-flex cursos">
          <div className="p-3 col-lg-4 col-md-4 col-12">
            <Card className="col-12">
              <CardImg top width="100%" src="https://images.unsplash.com/photo-1471899060976-bfd9378f87c7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="Card image cap" />
              <CardBody>
                <CardTitle>Curso básico</CardTitle>
                {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                <CardText className="text-left">Descripción...</CardText>
                <Link to="/curso-basico" className="btn btn-dark">Más información</Link>
              </CardBody>
            </Card>
          </div>
          <div className="p-3 col-lg-4 col-md-4 col-12">
            <Card className="col-12">
              <CardImg top width="100%" src="https://images.unsplash.com/photo-1525210988515-275c88b0b765?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" alt="Card image cap" />
              <CardBody>
                <CardTitle>Curso intermedio</CardTitle>
                {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                <CardText className="text-left">Descripción...</CardText>
                <Link to="/curso-basico" className="btn btn-dark">Más información</Link>
              </CardBody>
            </Card>
          </div>
          <div className="p-3 col-lg-4 col-md-4 col-12">
            <Card className="col-12">
              <CardImg top width="100%" src="https://images.unsplash.com/photo-1451417379553-15d8e8f49cde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" alt="Card image cap" />
              <CardBody>
                <CardTitle>Curso avanzado</CardTitle>
                {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                <CardText  className="text-left">Descripción...</CardText>
                <Link to="/curso-basico" className="btn btn-dark">Más información</Link>
              </CardBody>
            </Card>
          </div>
        </div>
    </div>
  );
}

export default Cursos; 

