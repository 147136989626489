// Dependencias
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import './css/'
import '../../App.css';

class Contenido extends Component {
    static propTypes = {
        body: PropTypes.object.isRequired
    };

    render() {
        const { body} = this.props;
        return(
            <div className="Content cuerpo">
                { body }
            </div>
        );
    }
}

export default Contenido;
