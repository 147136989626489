// Dependencias
import React from 'react';
import { Route, Switch } from 'react-router-dom'

// Components
import App from '../../App';
import Cursos from '../Cursos/Cursos';
import Portada from '../Portada/Portada';
import CursoUno from '../CursoUno/CursoUno';

const AppRoutes = () =>

    <App>
        <Switch>
            <Route path="/aula" component={() => { window.location.href = 'http://35.198.30.205/'; return null;}}/>
            <Route path="/cursos" component={Cursos} />
            <Route path="/curso-basico" component={CursoUno} />
            <Route path="/" component={Portada} />
        </Switch>
    </App>;

export default AppRoutes;