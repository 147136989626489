import React from 'react';
import '../../App.css';


function CursoUno() {
  return (
    <footer className="page-footer font-small bg-secondary sizeDos">
        <div className="footer-copyright text-light bg-secondary text-center">© 2020 Copyright - TibarMet.com </div>
    </footer>
  );
}

export default CursoUno; 